import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import './image-collage.scss';

export const ImageCollageComponent = ({ data, institute_id }) => {
    console.log("inside::" + data);
    console.error("institute id:" + institute_id);

    const images = data.imageData;

    return (
        <div className="image-collage col-sm-2">
            <Card className="h-full">
                <table className="table table-image w-full">
                    <tbody>
                        {/* Row 1 */}
                        <tr>
                            <td className="w-1/2">
                                {/* Image 1 */}
                                {
                                    images[0] != null ? (
                                        <Image 
                                            src={images[0].imageSrc} 
                                            zoomSrc={images[0].imageSrc} 
                                            alt="Image" 
                                            imageClassName="img-fluid img-thumbnail custom-image" 
                                            preview
                                        />
                                    ) : (
                                        <div className="placeholder-image">No Image</div>
                                    )
                                }
                            </td>
                            <td className="w-1/2">
                                {/* Image 2 */}
                                {
                                    images[1] != null ? (
                                        <Image 
                                            src={images[1].imageSrc} 
                                            zoomSrc={images[1].imageSrc} 
                                            alt="Image" 
                                            imageClassName="img-fluid img-thumbnail custom-image" 
                                            preview
                                        />
                                    ) : (
                                        <div className="placeholder-image">No Image</div>
                                    )
                                }
                            </td>
                        </tr>
                        {/* Row 2 */}
                        <tr>
                            <td className="w-1/2">
                                {/* Image 3 */}
                                {
                                    images[2] != null ? (
                                        <Image 
                                            src={images[2].imageSrc} 
                                            zoomSrc={images[2].imageSrc} 
                                            alt="Image" 
                                            imageClassName="img-fluid img-thumbnail custom-image" 
                                            preview
                                        />
                                    ) : (
                                        <div className="placeholder-image">No Image</div>
                                    )
                                }
                            </td>
                            <td className="w-1/2">
                                {/* Image 4 or View More */}
                                <div className="custom-container">
                                    {
                                        images[3] != null ? (
                                            <>
                                                <img 
                                                    src={images[3].imageSrc} 
                                                    className="img-fluid img-thumbnail custom-image last-item" 
                                                    alt="Image"
                                                />
                                                <div className="centered">
                                                    <Link to={`/gallery/${data.year}/${institute_id}`}>View More</Link>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="placeholder-image">No Image</div>
                                        )
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <h6 className="year-txt-style">{data.year}</h6>
                </div>
            </Card>
        </div>
    );
};
