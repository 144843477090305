export const SERVICE_URL = {
  TRUSTEE: {
    GET_ALL_TRUSTEES: "/trustee",
  },
  AWARDS: {
    GET_ALL_AWARDS: "/awards",
  },

  RTI: {
    GET_ALL_RTI: "/rti",
  },

  NOTICE: {
    GET_ALL_NOTICES: "/notice",
  },

  NOTICE_TYPE: {
    GET_ALL_NOTICE_TYPES: "/notice/type",
  },

  MEDIA_TALKS: {
    GET_ALL_MEDIA_TALKS: "/media",
  },

  IMPORTANT_LINKS: {
    GET_ALL_IMPORTANT_LINS: "/links",
  },

  STUDENT_ACHIEVEMENTS: {
    GET_ALL_ACHIEVEMENT: "/achievement/student",
    GET_ACHIEVEMENT_TYPES: "/achievement/type",
  },

  GRIEVANCES: {
    CREATE_GRIEVANCE: "/complaint",
  },

  MAGZINE: {
    GET_MAGZINE: "/magzine",
  },

  ACADEMIC_YEARS: {
    GET_ACADEMIC_YEAR: "/academic/year",
  },
  INSTITUTIONS: {
    GET_ALL_INSTITUTIONS: "/institute",
  },
  //Section details
  SCHOOL_INFO: {
    GET_SCHOOL_INFO: "/school/info/institute",
  },
  SCHOOL_STRENGTH: {
    GET_SCHOOL_STRENGTH: "/school/strength/institute",
  },
  PAST_RESULT: {
    GET_PAST_RESULT: "/past/result/",
  },
  SCHOLARSHIP: {
    GET_SCHOLARSHIP: "/scholarship/institute",
  },

  ADMISSION_INFO: {
    GET_ADMISSION_INFO: "/admission/info/institute",
  },
  SCHOOL_CONTACT: {
    GET_SCHOOL_CONTACT: "/school/contact/institute",
  },
  SCHOOL_ACTIVITY: {
    GET_SCHOOL_ACTIVITY: "/school/activity/institute",
    OTHER_ACTIVITY: "/other/activity/institute",
  },
  SCHOOL_PTA_MEMBER: {
    GET_SCHOOL_PTA_MEMBER: "/school/pta/member/",
  },
  ACHIEVEMENT_TYPE: {
    GET_ACHIEVEMENT_TYPE: "/achievement/type/institute",
  },
  ACHIEVEMENT: {
    GET_TEACHER_ACHIEVEMENT: "/achievement/teacher/institute",
    GET_STUDENT_ACHIEVEMENT: "/achievement/student/institute",
    CREATE_ACHIEVEMENT: "/achievement/institute",
  },
  ACADEMIC_CALENDAR: {
    GET_ACADEMIC_CALENDAR: "/school/calendar",
  },
  HM_PRINCIPAL: {
    GET_HM_PRINCIPAL: "/principal/institute",
  },
  SCHOOL_INFRASTRUCTURE: {
    GET_INFRASTRUCTURE: "/school/infra/institute",
  },
  SCHOOL_FACILITIES_OFFERED: {
    GET_FACILITIES: "/school/facility/institute",
  },
  GALLERY: {
    GET_GALLERY_IMAGES: "/school/gallery/institute",
  },
  ADMISSION_STUDENT_PORTAL_LINKS: {
    GET: "/portal-links"
  },
  VISITOR_COUNT: {
    UPDATE_VISITOR: "/visitor"
  }


};
