import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import './home.layout.scss'
import MATA from '../../assets/images/mata.png'
import { getData, postData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL';

import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
//import { ProductService } from './service/ProductService';
import Pic1 from '../../assets/images/home-page/1 Madam.jpg';
import Pic2 from '../../assets/images/home-page/2 Sir.jpg';
import Pic3 from '../../assets/images/home-page/3 VPR.jpg';
import Pic4 from '../../assets/images/home-page/4 VPR.jpg';
import Pic5 from '../../assets/images/home-page/5.jpeg';
import Pic6 from '../../assets/images/home-page/6.jpg';
import Pic7 from '../../assets/images/home-page/7 Varsha Gaikwad.jpeg';
import Pic8 from '../../assets/images/home-page/8 Vinod Tawade.jpeg';
import Pic9 from '../../assets/images/home-page/9 Kirit Somaiya.JPG';
import Pic10 from '../../assets/images/home-page/10.jpg';
import Pic11 from '../../assets/images/home-page/11 V.jpg';
import Pic12 from '../../assets/images/home-page/12 V.jpg';
import Pic13 from '../../assets/images/home-page/Devendra Fadnavis.jpg';
import Pic14 from '../../assets/images/home-page/Manohar Joshi.jpg';
import Pic15 from '../../assets/images/home-page/Narayan Rane.jpg';
import Pic16 from '../../assets/images/home-page/Sunil Raut.jpg';

import Pic17 from '../../assets/images/home-page/india_flag.jpg';
import Pic18 from '../../assets/images/home-page/har ghar tiranga.jpg';
import Pic19 from '../../assets/images/home-page/happy-independence-day-status.jpg';

import AdmissionOpen from '../../assets/images/admission open.png'


export const HomeLayout = () => {
    const [news, setNews] = useState([]);
    const isVisted = useRef(false);


    const [items, setItems] = useState([
        {
            order: 16,
            pic_url: Pic17,
            desc: ""
        },
        {
            order: 17,
            pic_url: Pic18,
            desc: ""
        },
        {
            order: 18,
            pic_url: Pic19,
            desc: ""
        },
        {
            order: 0,
            pic_url: Pic1,
            desc: ""
        },
        {
            order: 1,
            pic_url: Pic2,
            desc: ""
        },
        {
            order: 2,
            pic_url: Pic3,
            desc: ""
        },
        {
            order: 3,
            pic_url: Pic4,
            desc: ""
        },
        {
            order: 4,
            pic_url: Pic5,
            desc: ""
        },
        {
            order: 5,
            pic_url: Pic6,
            desc: ""
        },
        {
            order: 6,
            pic_url: Pic7,
            desc: ""
        },
        {
            order: 7,
            pic_url: Pic8,
            desc: ""
        },
        {
            order: 8,
            pic_url: Pic9,
            desc: ""
        },

        {
            order: 9,
            pic_url: Pic10,
            desc: ""
        },
        {
            order: 10,
            pic_url: Pic11,
            desc: ""
        },
        {
            order: 11,
            pic_url: Pic12,
            desc: ""
        },
        {
            order: 12,
            pic_url: Pic13,
            desc: ""
        },
        {
            order: 13,
            pic_url: Pic14,
            desc: ""
        },
        {
            order: 14,
            pic_url: Pic15,
            desc: ""
        },
        {
            order: 15,
            pic_url: Pic16,
            desc: ""
        }


    ]);


    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    useEffect(() => {
        if (isVisted.current) return;
        isVisted.current = true;

        getData(getURL("GET_ALL_NOTICES", "NOTICE"), (resp) => {
            //console.log("Notice");
            if (resp.status == 200) {
                setNews(resp.data.data);
                console.log(resp.data.data);
            }
        },
            (error) => {

            });

        
            //visitor count call
            postData(getURL("UPDATE_VISITOR", "VISITOR_COUNT"),{},(resp) => {
                console.log("Visitor count updated");
            },
                (error) => {
                        console.error(error);
                });



    }, []);




    const productTemplate = (item) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                <div className="mb-3" style={{ height: "auto", maxWidth: "100%" }}>
                    <Image src={item.pic_url} className="w-6 shadow-2" style={{ height: "auto", maxWidth: "100%" }}
                        preview zoomSrc={item.pic_url} width="100%"
                    />
                </div>
            </div>
        );
    };




    return (
        <div className='home-layout'>
            <div className='container'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-12 news'>
                            <div className='row'>
                                <div className='col-sm-2 updates'>
                                    <div className="top-news">
                                        <span className="top-news-title">
                                            Notices
                                        </span>

                                    </div>
                                </div>
                                <div className='col-sm-10 panel'>
                                    <marquee direction="left" loop={true}>
                                        <ul>

                                            {
                                                news.map(item => {
                                                    return <li key={item.id}>
                                                        <Link to="/notices">{item.title}</Link>
                                                    </li>
                                                })
                                            }

                                        </ul>
                                    </marquee>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-sm-4'>
                        </div>
                        <div className='col-sm-4'>
                            <img src={MATA} className='img-responsive' />
                            <center>
                                <h3 style={{ textDecoration: "none", color: "#000" }}><span style={{ color: "#07ab9a", width: "2px" }}>||</span> प्रज्वलितो ज्ञानमयोदीप <span style={{ color: "#07ab9a", width: "2px" }}>||</span></h3>
                            </center>
                            <center>
                                <a href="https://www.youtube.com/watch?v=uPAXnCGJpdI" style={{ textDecoration: "none", color: "#000" }}>
                                    <h3 className="quot">" १९६२ पासूनची संस्थेची यशोगाथा "</h3>
                                </a>
                            </center>

                        </div>
                        <div className='col-sm-4'>
                        </div>
                    </div>
                    <div className='row'></div>

                    {/*<div className='row'>
                        <div className='col-sm-12'>
                            <div className='card shadow goal-objective'>
                                <div className='header'>
                                    <h5>GOALS &amp; OBJECTIVES</h5>
                                </div>
                                <div className='body'>
                                    <div style={{ textDecoration: "none !important" }}>
                                        <li>Education to underprivileged classes and serving students to bring about social
                                            reformation.</li>
                                        <hr />
                                        <li>Devotion to mass education and commitment towards advancement.</li>
                                        <hr />
                                        <li>To offer educational programs that will blend creativity, analytical skills and career-
                                            oriented learning.</li>
                                        <hr />
                                        <li>To bridge the gap between traditional and need based education, vis-a-vis, inculcating
                                            international ethos.</li>
                                        <hr />
                                        <li>To develop a global outlook and capability of adaptation to the changing
                                            requirements, synthesizing conventional and modern thinking.</li>
                                        <hr />
                                        <li>To provide value-based education and to inculcate a sense of communal harmony and
                                            national integrity.</li>
                                    </div>
                                </div>

                            </div>
                        </div>
                                        </div>*/}
                    <div className='row'>
                        <div className="">
                            <Carousel value={items} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                                autoplayInterval={3000} itemTemplate={productTemplate} />
                        </div>
                    </div>

                    <div id="sidebar">
                        <div id="widget" className="sticky">
                            <div className="bounce">
                                <a href="/admission" target="_blank">
                                    <img src={AdmissionOpen}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
